import React from 'react';

import { IRenderWidgetProps } from '../../../../assets/modules/renderWidget/model';
import { getWidgetInitialiser } from '../../../../sharedModules/getWidgetInitialiser';
import { loadCSS } from '../../../../utils/loadCSS';
import { getInitialiser } from '../../../modules/getInitialiser';
import { GetInitialiserProps, WidgetWrapper } from '../../../types/GetInitialiserProps';
import { RequestType, getJSONFn } from '../../../types/GetJSON';
import { MerchantLinkData } from '../../../types/MerchantLinkData';
import { WidgetInitialiserOptions } from '../../../types/WidgetInitialiser';
import type { WidgetProps } from '../../../types/WidgetProps';
import { Consumer } from '../../GenericComponents/HawkWidget/HawkWidgetProviderContext';
import SimpleContracts from '../Contracts/SimpleContracts';
import SimpleRetail from '../Retail/SimpleRetail';
import SimpleSubscriptions from '../Subscriptions/SimpleSubscriptions';

loadCSS('simple');

interface SimpleProps {
  endpoint: WidgetProps['endpoint'];
  getDealData: WidgetProps['getDealData'];
  dealData: WidgetProps['dealData'];
  getJSON: WidgetProps['getJSON'];
  sendAnalytics: WidgetProps['sendAnalytics'];
  setDealData: WidgetProps['setDealData'];
  translate: WidgetProps['translate'];
  widgetEndpoint: WidgetProps['widgetEndpoint'];
  genericSharedComponents: WidgetProps['genericSharedComponents'];
}

const Simple: React.FC<SimpleProps> = (props) => {
  const {
    genericSharedComponents: { HawkWidgetAdvanced, Main },
  } = props;
  return (
    <HawkWidgetAdvanced
      {...{
        ...props,
        selectedSwitcherItem: 'data',
        switcherItems: ['data', 'texts', 'minutes'],
      }}
    >
      <Consumer>
        {({ activeTab }): JSX.Element => {
          return (
            <Main
              {...{
                ...props,
                className: 'simple',
              }}
            >
              {((): JSX.Element => {
                switch (activeTab?.category) {
                  case 'subscriptions':
                    return <SimpleSubscriptions />;
                  case 'contracts':
                    return <SimpleContracts />;
                  default:
                    return <SimpleRetail />;
                }
              })()}
            </Main>
          );
        }}
      </Consumer>
    </HawkWidgetAdvanced>
  );
};

Simple.getInitialiserProps = async (
  { el, site, articleUrl, ...restRenderProps }: IRenderWidgetProps,
  attributes: DOMStringMap,
  getJSON: getJSONFn<RequestType.WIDGET_REQUEST>,
  editorial: boolean,
  defaultParams: WidgetInitialiserOptions['defaultParams'],
  endpoint: string,
  url: string,
  origin: string,
  dataLinkMerchant: MerchantLinkData,
): GetInitialiserProps => {
  // Get the initialiser for the widget & render it when the initialiser resolves
  const { props, type, apiCall } = await getWidgetInitialiser({
    editorial,
    defaultParams,
    endpoint,
    url,
    origin,
    dataLinkMerchant,
    site,
    getJSON,
    placeholder: el,
    articleUrl,
    attributes,
    getInitialiser,
    ...restRenderProps,
  });

  return { props, type, apiCall, widgetWrapper: WidgetWrapper.DIV };
};

export default Simple;
